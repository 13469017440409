import { Component, Prop, Vue } from 'vue-property-decorator';
import { analyticsClick, ClickData } from '@/analytics';

@Component
export default class GAnalytics extends Vue {
  @Prop({ type: Object, required: false })
  public readonly clickData?: ClickData;

  /**
   * send click data bound to clickData prop
   */
  public sendClick() {
    if (this.clickData) {
      this.sendClickData(this.clickData);
    }
  }

  /**
   * send click data without binding to clickData prop
   */
  public sendClickData(data: ClickData) {
    analyticsClick(data);
  }

  protected render() {
    return this.$scopedSlots.default?.({
      sendClick: this.sendClick,
      sendClickData: this.sendClickData,
    });
  }
}
