
import { Component, Vue } from 'vue-property-decorator';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import { DeepReadonly } from 'ts-essentials';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { AssessmentModule } from '@/Rpa/store/modules/assessment.module';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import { BaseUser } from '@/types/users.types';
import { locationOptions } from '@/Rpa/constants';
import { GskLocation } from '@/Rpa/types/assessment.types';
import GskMultiSelect from '@/components/gsk-components/GskMultiSelect.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import { AppEnumsBase } from '@/types/enum.types';
import { SelectOption } from '@/components/form/form.types';
import GAnalytics from '@/components/GAnalytics';
import { ClickData } from '@/analytics';

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

@Component({
  components: {
    FullScreenForm,
    GTextfield,
    GPeoplePicker,
    GskMultiSelect,
    GSelect,
    GAnalytics,
  },
})
export default class GeneralDetails extends Vue {
  public processOwnerTooltipText =
    'Process Owner is the entity accountable for the process dimension across functions, delivery locations, and third party providers to ensure an enterprise-wide and truly cross-functional approach to the way services are delivered​​.';

  get processName(): string {
    return AssessmentModule.assessmentResults.general.processName as string;
  }

  set processName(name: string) {
    AssessmentModule.setProcessName(name);
  }

  get processDescription(): string {
    return AssessmentModule.assessmentResults.general.processDescription as string;
  }

  set processDescription(desc: string) {
    AssessmentModule.setProcessDescription(desc);
  }

  get businessProcessOwner(): BaseUser[] {
    return AssessmentModule.assessmentResults.general.businessProcessOwner as BaseUser[];
  }

  set businessProcessOwner(owner: BaseUser[]) {
    if (owner.length > 1) {
      owner.pop();
    }
    AssessmentModule.setBusinessProcessOwner(owner);
  }

  get enterpriseProcessOwner(): BaseUser[] {
    return AssessmentModule.assessmentResults.general.enterpriseProcessOwner as BaseUser[];
  }

  set enterpriseProcessOwner(owner: BaseUser[]) {
    if (owner.length > 1) {
      owner.pop();
    }
    AssessmentModule.setEnterpriseProcessOwner(owner);
  }

  get globalProcessOwner(): BaseUser[] {
    return AssessmentModule.assessmentResults.general.globalProcessOwner as BaseUser[];
  }

  set globalProcessOwner(owner: BaseUser[]) {
    if (owner.length > 1) {
      owner.pop();
    }
    AssessmentModule.setGlobalProcessOwner(owner);
  }

  get processLocation(): string[] {
    return AssessmentModule.assessmentResults.general.processLocation as string[];
  }

  set processLocation(location: string[]) {
    AssessmentModule.setProcessLocation(location);
  }

  get locationOptions(): GskLocation[] {
    return locationOptions;
  }

  get processCategory(): string {
    return AssessmentModule.assessmentResults.general.processCategory as string;
  }

  set processCategory(category: string) {
    AssessmentModule.setProcessCategory(category);
  }

  get processCategories(): SelectOption[] {
    const businessUnits: DeepReadonly<AppEnumsBase['businessUnit']> =
      EnumsModule.enumsLists.businessUnit;
    return businessUnits.map(bu => {
      return {
        value: bu.name,
      };
    });
  }

  get generalDetailsAnalytics(): ClickData {
    return {
      clickTarget: 'Add General Details',
    };
  }

  nextStep(clickAnalytics: () => void): void {
    clickAnalytics();
    AssessmentModule.nextStep();
  }

  get isNotComplete(): boolean {
    return (
      !this.processName ||
      !this.processDescription ||
      this.businessProcessOwner.length === 0 ||
      !this.processCategory
    );
  }
}
