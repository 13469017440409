
import { Component, Vue } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { AssessmentModule } from '@/Rpa/store/modules/assessment.module';
import { BusinessCompliant } from '@/Rpa/types/assessment.types';
import MarkdownEditor from '@/components/MarkdownEditor.vue';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import GCheckboxGroup from '@/components/gsk-components/GskCheckboxGroup.vue';
import {
  processRegulationTitle,
  governingProcessTitle,
  processTypeTitle,
  personalInfoTitle,
  systemsTitle,
  integrationOptions,
} from '@/Rpa/constants';
import GskMultiSelect from '@/components/gsk-components/GskMultiSelect.vue';
import { SelectOption } from '@/components/form/form.types';
import { ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

@Component({
  components: {
    FullScreenForm,
    MarkdownEditor,
    GRadioGroup,
    GCheckboxGroup,
    GskMultiSelect,
    GAnalytics,
  },
})
export default class BusinessDetails extends Vue {
  public processRegulationTooltip = 'GxP, SOX, or other regulations.';
  public governingProcessTooltip =
    'Standard operating procedures or other documents describing the process';
  public processRegulations: BusinessCompliant[] = [
    {
      value: 'Yes',
      checked: false,
    },
    {
      value: 'No',
      checked: false,
    },
    {
      value: "I don't know",
      checked: false,
    },
  ];

  public regulationOptions: BusinessCompliant[] = [
    {
      value: 'GxP',
      tooltip: 'Good practice quality guidelines and regulations',
      checked: false,
    },
    {
      value: 'SoX',
      tooltip: 'Sarbanes–Oxley Act regulation of financial practice and corporate governance',
      checked: false,
    },
    {
      value: 'Other',
      checked: false,
    },
  ];

  public governingProcessOptions: BusinessCompliant[] = [
    {
      value: 'Yes',
      checked: false,
    },
    {
      value: 'No',
      checked: false,
    },
    {
      value: "I don't know",
      checked: false,
    },
  ];

  public processTypeOptions: BusinessCompliant[] = [
    {
      value: 'Critical and Sensitive',
      tooltip:
        'GSK Confidential Information where improper disclosure, loss of integrity, or unavailability of the data could result in severe quality, environmental health and safety, legal, human capital, regulatory, reputational, strategic objectives, or financial impact to GSK.',
      checked: false,
    },
    {
      value: 'Confidential',
      tooltip:
        'Any Proprietary Information and information entrusted to GSK by third parties, where unauthorised disclosure could harm GSK interests.',
      checked: false,
    },
    {
      value: 'Proprietary',
      tooltip:
        'All information and collections of information obtained or created by or on behalf of GSK.',
      checked: false,
    },
    {
      value: 'Public',
      tooltip:
        'GSK Proprietary Information that has been approved for public release through a formal mechanism in accordance with GSK policy on Protecting and Mitigating Risk from External and Internal Communications Activities – POL-GSK-301',
      checked: false,
    },
    {
      value: 'I don’t know',
      checked: false,
    },
  ];

  public personalInfoOptions: BusinessCompliant[] = [
    {
      value: 'None',
      checked: false,
    },
    {
      value: 'Basic PI',
      tooltip: 'Information that can identify, or reasonably be used to identify, someone.',
      checked: false,
    },
    {
      value: 'Restricted PI',
      tooltip:
        'Shared based on a “need to know” basis, only to those who have to perform legitimate business activities within or for GSK. May require enhanced controls and/or may be subject to breach notification laws and regulations.',
      checked: false,
    },
    {
      value: 'Sensitive PI',
      tooltip:
        'SPI refers to any information whose unauthorized acquisition, use, modification, loss or disclosure presents a greater risk of harm to the relevant individual which may require additional protection measures.',
      checked: false,
    },
    {
      value: 'I don’t know',
      checked: false,
    },
  ];

  get systemsTitle(): string {
    return systemsTitle;
  }

  get systems(): string {
    return AssessmentModule.assessmentResults.business.systems as string;
  }

  set systems(sys: string) {
    AssessmentModule.setSystems(sys);
  }

  get systemIntegrations(): string[] {
    return AssessmentModule.assessmentResults.business.systemIntegrations as string[];
  }

  set systemIntegrations(integrations: string[]) {
    AssessmentModule.setSystemIntegrations(integrations);
  }

  get integrationOptions(): SelectOption[] {
    return integrationOptions;
  }

  get regulationList(): string {
    return AssessmentModule.assessmentResults.business.regulationList as string;
  }

  set regulationList(reg: string) {
    AssessmentModule.setRegulationList(reg);
  }

  get governingProcessList(): string {
    return AssessmentModule.assessmentResults.business.governingProcessList as string;
  }

  set governingProcessList(gov: string) {
    AssessmentModule.setGoverningProcessList(gov);
  }

  get hasRegulations(): boolean {
    return AssessmentModule.hasRegulations;
  }

  get isOtherRegulation(): boolean {
    return AssessmentModule.isOtherRegulation;
  }

  get hasGovProcess(): boolean {
    return AssessmentModule.hasGovProcess;
  }

  get hasSoXReg(): boolean {
    return AssessmentModule.hasSoXReg;
  }

  get processRegulationTitle(): string {
    return processRegulationTitle;
  }

  get processRegulation(): string {
    return cloneDeep(AssessmentModule.assessmentResults.business.processRegulation as string);
  }

  set processRegulation(reg: string) {
    AssessmentModule.setProcessRegulation(reg);
  }

  get regulations(): string[] {
    return AssessmentModule.assessmentResults.business.regulations as string[];
  }

  set regulations(regs: string[]) {
    AssessmentModule.setRegulations(regs);
  }

  get processTypeTitle(): string {
    return processTypeTitle;
  }

  get processTypeRadio(): string {
    return AssessmentModule.assessmentResults.business.processTypeRadio as string;
  }

  set processTypeRadio(type: string) {
    AssessmentModule.setProcessTypeRadio(type);
  }

  get processTypeCheck(): string[] {
    return AssessmentModule.assessmentResults.business.processTypeChecks as string[];
  }

  set processTypeCheck(checks: string[]) {
    AssessmentModule.setProcessTypeChecks(checks);
  }

  get governingProcessTitle(): string {
    return governingProcessTitle;
  }

  get governingProcess(): string {
    return AssessmentModule.assessmentResults.business.governingProcess as string;
  }

  set governingProcess(value: string) {
    AssessmentModule.setGoverningProcess(value);
  }

  get personalInfoTitle(): string {
    return personalInfoTitle;
  }

  get personalInfoRadio(): string {
    return AssessmentModule.assessmentResults.business.personalInfoRadio as string;
  }

  set personalInfoRadio(info: string) {
    AssessmentModule.setPersonalInfoRadio(info);
  }

  get personalInfoChecks(): string[] {
    return AssessmentModule.assessmentResults.business.personalInfoChecks as string[];
  }

  set personalInfoChecks(checks: string[]) {
    AssessmentModule.setPersonalInfoChecks(checks);
  }

  back(): void {
    AssessmentModule.backStep();
  }

  nextStep(clickAnalytics: () => void): void {
    clickAnalytics();
    AssessmentModule.nextStep();
  }

  get biznessAnalytics(): ClickData {
    return {
      clickTarget: 'Add Business Details',
    };
  }

  get hasNoTypeInfo(): boolean {
    if (this.hasSoXReg) {
      // checkbox fields
      return this.personalInfoChecks.length === 0 || this.processTypeCheck.length === 0;
    } else {
      // radio fields
      return !this.personalInfoRadio || !this.processTypeRadio;
    }
  }

  get hasNoRegulations(): boolean {
    if (this.hasRegulations) {
      return this.regulations.length === 0;
    } else {
      return false;
    }
  }
}
