
import { Component, Vue } from 'vue-property-decorator';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import once from 'lodash/once';
import cloneDeep from 'lodash/cloneDeep';
import { nanoid } from 'nanoid';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { UserModule } from '@/store/modules/user.module';
import { AssessmentModule } from '@/Rpa/store/modules/assessment.module';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { SelectOption } from '@/components/form/form.types';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { RadioTableRow } from '@/Rpa/types/assessment.types';
import RadioTable from '@/Rpa/components/RadioTable.vue';
import { ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ATL_JQ_PAGE_PROPS: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jQuery: any;
  }
}

const issueCollector = () => {
  const { hostname } = location;
  if (/^developer\./.test(hostname)) {
    return process.env.VUE_APP_JIRA_ISSUE_COLLECTOR_PROD_URL;
  }
  return process.env.VUE_APP_JIRA_ISSUE_COLLECTOR_URL || '';
}

const setUpJiraCollector = once(() => {
  let srcs = [
    issueCollector(),
    'https://cdnjs.cloudflare.com/ajax/libs/js-yaml/3.14.0/js-yaml.min.js',
  ];
  srcs.forEach(src => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.head.appendChild(script);
  });
  window.ATL_JQ_PAGE_PROPS = {
    triggerFunction: function (showCollectorDialog: () => void) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      document.addEventListener('rpa', function (e: any) {
        window.ATL_JQ_PAGE_PROPS = window.jQuery.extend(window.ATL_JQ_PAGE_PROPS, {
          fieldValues: window.jQuery.extend(window.ATL_JQ_PAGE_PROPS.fieldValues, {
            summary: e.detail.assessmentProcess,
            fullname: e.detail.assessmentName,
            email: e.detail.assessmentEmail,
            description:
              '{code:javascript}\n# Opportunity Assessment Tool Results\n' +
              e.detail.assessmentDescription +
              '\n# End Results\n{code}',
          }),
        });
        showCollectorDialog();
      });
    },
    fieldValues: {
      summary: AssessmentModule.assessmentResults.general.processName,
    },
  };
});

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

@Component({
  components: {
    FullScreenForm,
    GSelect,
    GTextfield,
    RadioTable,
    GAnalytics,
  },
})
export default class ValueDetails extends Vue {
  private percentageTooltip =
    'Approximate percentage of the number of steps in the manual process that can be automated';
  public valueTableColumns: string[] = [
    'This automation will…',
    'Low',
    'Medium',
    'High',
    'Very High',
  ];

  get valueTableRows(): RadioTableRow[] {
    return AssessmentModule.assessmentResults.value.valueTableRows as RadioTableRow[];
  }

  set valueTableRows(rows: RadioTableRow[]) {
    AssessmentModule.setValueTableRows(rows);
  }

  get automationPercent(): number {
    return cloneDeep(AssessmentModule.assessmentResults.value.automationPercent as number);
  }

  onSliderChange(e: Event) {
    const percent = Math.round(Number((e.target as HTMLInputElement).value));

    AssessmentModule.setAutomationPercent(percent);
  }

  public repeatOptions: SelectOption[] = [
    {
      value: 'Daily',
    },
    {
      value: 'Weekly',
    },
    {
      value: 'Monthly',
    },
    {
      value: 'Yearly',
    },
  ];

  get automationRepeats(): string {
    return AssessmentModule.assessmentResults.value.automationRepeats as string;
  }

  set automationRepeats(repeat: string) {
    AssessmentModule.setAutomationRepeat(repeat);
  }

  get numberOfRepeats(): number {
    return AssessmentModule.assessmentResults.value.numberOfRepeats as number;
  }

  set numberOfRepeats(num: number) {
    AssessmentModule.setNumberOfRepeats(num);
  }

  get numberOfEmployees(): number {
    return AssessmentModule.assessmentResults.value.numberOfEmployees as number;
  }

  set numberOfEmployees(num: number) {
    AssessmentModule.setNumberOfEmployees(num);
  }

  get numberOfHours(): number {
    return AssessmentModule.assessmentResults.value.numberOfHours as number;
  }

  set numberOfHours(hours: number) {
    AssessmentModule.setNumberOfHours(hours);
  }

  get numberOfDays(): number {
    return AssessmentModule.assessmentResults.value.numberOfDays as number;
  }

  set numberOfDays(days: number) {
    AssessmentModule.setNumberOfDays(days);
  }

  created() {
    setUpJiraCollector();
    const traceId = nanoid(9);
    AssessmentModule.setTraceId(traceId);
  }

  get processName(): string {
    return AssessmentModule.assessmentResults.general.processName as string;
  }

  back(): void {
    AssessmentModule.backStep();
  }

  get submitAnalytics(): ClickData {
    return {
      clickTarget: 'Submit Process',
    };
  }

  submitAssessment(clickAnalytics: () => void) {
    clickAnalytics();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let assessmentDescription = (window as any).jsyaml.safeDump({
      quizResults: AssessmentModule.quizResults,
      comments: AssessmentModule.comments,
    });
    document.dispatchEvent(
      new CustomEvent('rpa', {
        detail: {
          assessmentProcess: this.processName,
          assessmentName: UserModule.user.fullName,
          assessmentEmail: UserModule.user.email,
          assessmentDescription,
        },
      }),
    );
    // Emit Submission
    this.$emit('submit');
  }

  get isNotComplete(): boolean {
    return (
      this.automationPercent === 0 ||
      !this.automationRepeats ||
      !this.numberOfRepeats ||
      !this.numberOfEmployees ||
      !this.numberOfHours ||
      !this.numberOfDays ||
      !this.hasTableValue
    );
  }

  get hasTableValue(): boolean {
    let checked = [];
    this.valueTableRows.forEach(row => {
      row.radios.forEach(r => {
        if (r.checked) {
          checked.push(r.value);
        }
      });
    });
    return this.valueTableRows.length === checked.length;
  }
}
