
import { Component, Vue } from 'vue-property-decorator';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import cloneDeep from 'lodash/cloneDeep';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { AssessmentModule } from '@/Rpa/store/modules/assessment.module';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import RadioTable from '@/Rpa/components/RadioTable.vue';
import { RadioTableRow, ReadinessLevel, ReadinessLevelImages } from '@/Rpa/types/assessment.types';
import { ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import oneDefault from '../../assets/off-process-automation-1.svg';
// @ts-ignore
import oneChecked from '../../assets/on-process-automation-1.svg';
// @ts-ignore
import twoDefault from '../../assets/off-network-3.svg';
// @ts-ignore
import twoChecked from '../../assets/on-network-3.svg';
// @ts-ignore
import threeDefault from '../../assets/off-network-4.svg';
// @ts-ignore
import threeChecked from '../../assets/on-network-4.svg';
/* eslint-enable @typescript-eslint/ban-ts-comment */

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

@Component({
  components: {
    FullScreenForm,
    GRadioGroup,
    RadioTable,
    GAnalytics,
  },
})
export default class AutomationDetails extends Vue {
  public radioTableColumns: string[] = ['Process structure', 'Yes', 'No'];

  public readinessImageMap: Record<string, ReadinessLevelImages> = {
    'Level 1': {
      defaultImage: oneDefault,
      checkedImage: oneChecked,
    },
    'Level 2': {
      defaultImage: twoDefault,
      checkedImage: twoChecked,
    },
    'Level 3': {
      defaultImage: threeDefault,
      checkedImage: threeChecked,
    },
  };

  image(level: string, checked: boolean): string {
    return checked
      ? this.readinessImageMap[level].checkedImage
      : this.readinessImageMap[level].defaultImage;
  }

  get readinessLevels(): ReadinessLevel[] {
    return cloneDeep(
      AssessmentModule.assessmentResults.automation.readinessLevels as ReadinessLevel[],
    );
  }

  onChange(e: Event) {
    let levels = this.readinessLevels;

    levels.forEach(l => {
      if (l.level === (e.target as HTMLInputElement).value) {
        l.checked = true;
      } else {
        l.checked = false;
      }
    });

    AssessmentModule.setReadinessLevels(levels);
  }

  get radioTableRows(): RadioTableRow[] {
    return AssessmentModule.assessmentResults.automation.automationTableRows as RadioTableRow[];
  }

  set radioTableRows(rows: RadioTableRow[]) {
    AssessmentModule.setAutomationTableRows(rows);
  }

  isChecked(checked: boolean): string {
    return checked ? 'Oval blue' : 'Oval gray';
  }

  back(): void {
    AssessmentModule.backStep();
  }

  nextStep(clickAnalytics: () => void): void {
    clickAnalytics();
    AssessmentModule.nextStep();
  }

  get automationAnalytics(): ClickData {
    return {
      clickTarget: 'Add Automation Details',
    };
  }

  get isNotComplete(): boolean {
    return !this.hasTableValue || !this.hasReadinessLevel;
  }

  get hasTableValue(): boolean {
    let checked = [];
    this.radioTableRows.forEach(row => {
      row.radios.forEach(r => {
        if (r.checked) {
          checked.push(r.value);
        }
      });
    });
    return this.radioTableRows.length === checked.length;
  }

  get hasReadinessLevel(): boolean {
    let checked = [];
    this.readinessLevels.forEach(level => {
      if (level.checked) {
        checked.push(level.level);
      }
    });
    return checked.length === 1;
  }
}
