import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { UITab } from '@/types';
import {
  AssessmentTool,
  AssessmentValue,
  ProcessOwner,
  RadioTableRow,
  ReadinessLevel,
} from '@/Rpa/types/assessment.types';
import { BaseUser } from '@/types/users.types';
import {
  governingProcessTitle,
  personalInfoTitle,
  processRegulationTitle,
  processTypeTitle,
  systemsTitle,
} from '@/Rpa/constants';

function initialTabsState(): UITab[] {
  return [
    {
      component: 'GeneralDetails',
      disabled: false,
      key: 'general-details',
      text: 'Add general details',
    },
    {
      component: 'AutomationDetails',
      disabled: true,
      key: 'automation-details',
      text: 'Add automation details',
    },
    {
      component: 'BusinessDetails',
      disabled: true,
      key: 'business-details',
      text: 'Add business details',
    },
    {
      component: 'ValueDetails',
      disabled: true,
      key: 'value-details',
      text: 'Add value details',
    },
  ];
}

function initialActiveTab() {
  return initialTabsState()[0];
}

function initialAssessmentState(): AssessmentTool {
  return {
    general: {
      processName: '',
      processDescription: '',
      businessProcessOwner: [],
      enterpriseProcessOwner: [],
      globalProcessOwner: [],
      processLocation: [],
      processCategory: '',
      traceId: '',
    },
    automation: {
      automationTableRows: [
        {
          title: 'Is the process highly manual and repetitive?',
          tooltip:
            'An employee must perform manual repetitive tasks in order to successfully complete the process.',
          radios: [
            { name: 'isManualProcess', value: 'Yes', checked: false },
            { name: 'isManualProcess', value: 'No', checked: false },
          ],
        },
        {
          title: 'Is the process well documented?',
          tooltip:
            'Clear instructions are recorded, outlining the specific tasks to successfully complete the process.',
          radios: [
            { name: 'isProcessDocumented', value: 'Yes', checked: false },
            { name: 'isProcessDocumented', value: 'No', checked: false },
          ],
        },
        {
          title: 'Is the process rule-based?',
          tooltip:
            'The execution of process steps is governed by the application of a set of explicit rules for manipulating data and can be easily applied.',
          radios: [
            { name: 'isRuleBasedProcess', value: 'Yes', checked: false },
            { name: 'isRuleBasedProcess', value: 'No', checked: false },
          ],
        },
        {
          title: 'Does the process include readable electronic inputs?',
          tooltip:
            'Data used in decision making or processing needs to be structured and in an electronic or paper based format.',
          radios: [
            { name: 'hasReadableInputs', value: 'Yes', checked: false },
            { name: 'hasReadableInputs', value: 'No', checked: false },
          ],
        },
        {
          title: 'Does the process have a high volume of manual transactions?',
          tooltip:
            'A significant number of manual steps to be executed and/or the process is executed a significant number of times in a specific time period',
          radios: [
            { name: 'hasManualTransactions', value: 'Yes', checked: false },
            { name: 'hasManualTransactions', value: 'No', checked: false },
          ],
        },
        {
          title: 'Is there an alternative minor automation available?',
          tooltip:
            'Alternative ways to could accomplish the same task which are less human dependent',
          radios: [
            { name: 'hasAltMethod', value: 'Yes', checked: false },
            { name: 'hasAltMethod', value: 'No', checked: false },
          ],
        },
        {
          title: 'Are there any major system changes expected?',
          tooltip:
            'Are the system or systems on which the task are executed due for significant upgrade that will require changes to the process and/or training to use the new system?',
          radios: [
            { name: 'hasExpectedChanges', value: 'Yes', checked: false },
            { name: 'hasExpectedChanges', value: 'No', checked: false },
          ],
        },
      ],
      readinessLevels: [
        {
          level: 'Level 1',
          title: 'As Is Process Automation',
          summary:
            'This level represents automation of the existing process and essentially involves the bot executing process steps in the same way as a human operator.',
          checked: false,
        },
        {
          level: 'Level 2',
          title: 'Process Optimisation Initiative',
          summary:
            'This level represents a review of the process with an automation perspective, without fundamentally changing it, in order to maximise the value of automation prior to designing the bot.',
          checked: false,
        },
        {
          level: 'Level 3',
          title: 'Process Transformation Initiative',
          summary:
            'This level represents the most complex approach, but also the ideal state of a process for automation. Processes that have been redefined can then form the inputs for the RPA initiative.',
          checked: false,
        },
      ],
    },
    business: {
      systemIntegrations: [],
      systems: ``,
      regulationList: ``,
      governingProcessList: ``,
      processRegulation: '',
      regulations: [],
      governingProcess: '',
      processTypeRadio: '',
      processTypeChecks: [],
      personalInfoRadio: '',
      personalInfoChecks: [],
    },
    value: {
      automationPercent: 0,
      automationRepeats: '',
      numberOfRepeats: '',
      numberOfEmployees: '',
      numberOfHours: '',
      numberOfDays: '',
      valueTableRows: [
        {
          title: 'Improve quality through error reduction.',
          radios: [
            { name: 'errorReduction', value: 'Low', checked: false },
            { name: 'errorReduction', value: 'Medium', checked: false },
            { name: 'errorReduction', value: 'High', checked: false },
            { name: 'errorReduction', value: 'Very High', checked: false },
          ],
        },
        {
          title: 'Improve internal service delivery.',
          radios: [
            { name: 'internalService', value: 'Low', checked: false },
            { name: 'internalService', value: 'Medium', checked: false },
            { name: 'internalService', value: 'High', checked: false },
            { name: 'internalService', value: 'Very High', checked: false },
          ],
        },
        {
          title: 'Improve employee experience/morale.',
          radios: [
            { name: 'employeeMorale', value: 'Low', checked: false },
            { name: 'employeeMorale', value: 'Medium', checked: false },
            { name: 'employeeMorale', value: 'High', checked: false },
            { name: 'employeeMorale', value: 'Very High', checked: false },
          ],
        },
        {
          title: 'Improve external customer satisfaction.',
          radios: [
            { name: 'satisfaction', value: 'Low', checked: false },
            { name: 'satisfaction', value: 'Medium', checked: false },
            { name: 'satisfaction', value: 'High', checked: false },
            { name: 'satisfaction', value: 'Very High', checked: false },
          ],
        },
        {
          title: 'Adhere to regulatory compliance.',
          radios: [
            { name: 'compliance', value: 'Low', checked: false },
            { name: 'compliance', value: 'Medium', checked: false },
            { name: 'compliance', value: 'High', checked: false },
            { name: 'compliance', value: 'Very High', checked: false },
          ],
        },
      ],
    },
  };
}

@Module({ name: 'assessment', store, dynamic: true, namespaced: true })
class Assessment extends VuexModule {
  public activeTab: UITab = initialActiveTab();
  public tabList: UITab[] = initialTabsState();

  public assessmentResults: AssessmentTool = initialAssessmentState();

  public get quizResults(): Record<string, AssessmentValue> {
    const { automation, business, value } = this.assessmentResults;
    const { valueTableRows } = value;
    const { automationTableRows, readinessLevels } = automation;
    const {
      systemIntegrations,
      systems,
      processRegulation,
      regulations,
      regulationList,
      governingProcess,
      governingProcessList,
      processTypeChecks,
      personalInfoChecks,
      processTypeRadio,
      personalInfoRadio,
    } = business;
    const results: Record<string, AssessmentValue> = {};
    (automationTableRows as RadioTableRow[]).forEach((row: RadioTableRow) => {
      row.radios.forEach(radio => {
        if (radio.checked) {
          results[row.title] = radio.value;
        }
      });
    });
    (readinessLevels as ReadinessLevel[]).forEach((row: ReadinessLevel) => {
      if (row.checked) {
        results['Readiness Level'] = row.level;
      }
    });

    results['System Integrations'] = systemIntegrations;

    results[systemsTitle] = systems;

    results[processRegulationTitle] = processRegulation;

    if (this.hasRegulations) {
      const regs: string[] = [];
      (regulations as string[]).forEach((check: string) => {
        regs.push(check);
        if (check === 'Other') {
          regs.push(regulationList as string);
        }
      });
      results['Regulations'] = regs;
    }

    results[governingProcessTitle] = governingProcess;

    if (this.hasGovProcess) {
      results['Governing Processes'] = governingProcessList;
    }

    if (this.hasSoXReg) {
      // Process Type Checks
      results[processTypeTitle] = processTypeChecks;
      results[personalInfoTitle] = personalInfoChecks;
    } else {
      // Process Type Radio
      results[processTypeTitle] = processTypeRadio;
      results[personalInfoTitle] = personalInfoRadio;
    }

    (valueTableRows as RadioTableRow[]).forEach((row: RadioTableRow) => {
      row.radios.forEach(radio => {
        if (radio.checked) {
          results[row.title] = radio.value;
        }
      });
    });
    return results;
  }

  public get comments(): Record<string, string | ProcessOwner | string[]> {
    const {
      processDescription,
      businessProcessOwner,
      enterpriseProcessOwner,
      globalProcessOwner,
      processLocation,
      processCategory,
      traceId,
    } = this.assessmentResults.general;
    const {
      automationPercent,
      automationRepeats,
      numberOfRepeats,
      numberOfEmployees,
      numberOfHours,
      numberOfDays,
    } = this.assessmentResults.value;

    const { fullName, email, mudId } = (businessProcessOwner as ProcessOwner[])[0];
    const bpo = {
      fullName,
      email,
      mudId,
    };

    const record: Record<string, string | ProcessOwner | string[]> = {
      'Trace ID': traceId as string,
      'Process Description': processDescription as string,
      'Automation Percentage': automationPercent as string,
      'Automation Repeats': automationRepeats as string,
      'Number of Repeats': numberOfRepeats as string,
      'Number of employees': numberOfEmployees as string,
      'Number of hours': numberOfHours as string,
      'Number of days': numberOfDays as string,
      'Process Business Unit': processCategory as string,
      'Process Location(s)': processLocation as string[],
      'Process Owner': bpo as ProcessOwner,
    };

    if (this.hasEnterpriseOwner) {
      const { fullName, email, mudId } = (enterpriseProcessOwner as ProcessOwner[])[0];
      record['Enterprise Process Owner'] = {
        fullName,
        email,
        mudId,
      };
    }

    if (this.hasGlobalOwner) {
      const { fullName, email, mudId } = (globalProcessOwner as ProcessOwner[])[0];
      record['Global Process Owner'] = {
        fullName,
        email,
        mudId,
      };
    }

    return record;
  }

  public get hasRegulations(): boolean {
    return this.assessmentResults.business.processRegulation === 'Yes';
  }

  public get isOtherRegulation(): boolean {
    return (this.assessmentResults.business.regulations as string[]).includes('Other');
  }

  public get hasGovProcess(): boolean {
    return this.assessmentResults.business.governingProcess === 'Yes';
  }

  public get hasSoXReg(): boolean {
    return (this.assessmentResults.business.regulations as string[]).includes('SoX');
  }

  public get hasEnterpriseOwner(): boolean {
    const { enterpriseProcessOwner } = this.assessmentResults.general;
    return (enterpriseProcessOwner as BaseUser[]).length > 0;
  }

  public get hasGlobalOwner(): boolean {
    const { globalProcessOwner } = this.assessmentResults.general;
    return (globalProcessOwner as BaseUser[]).length > 0;
  }

  @Mutation
  public updateActiveTab(newTab: UITab): void {
    this.activeTab = newTab;
  }

  @Mutation
  public nextStep(): void {
    const i = this.tabList.findIndex((tab: UITab): boolean => tab.key === this.activeTab.key);
    const nextIndex = i + 1;
    const lastIndex = this.tabList.length - 1;
    if (i === -1 || i === lastIndex) {
      return;
    } else {
      const nextTab = this.tabList[nextIndex];
      nextTab.disabled = false;
      this.activeTab = nextTab;
    }
  }

  @Mutation
  public backStep(): void {
    const i = this.tabList.findIndex((tab: UITab): boolean => tab.key === this.activeTab.key);
    const prevIndex = i - 1;
    const firstIndex = 0;
    if (i === -1 || i === firstIndex) {
      return;
    } else {
      this.activeTab = this.tabList[prevIndex];
    }
  }

  @Mutation
  public setProcessName(name: string): void {
    this.assessmentResults.general.processName = name;
  }

  @Mutation
  public setProcessDescription(desc: string): void {
    this.assessmentResults.general.processDescription = desc;
  }

  @Mutation
  public setBusinessProcessOwner(owner: BaseUser[]): void {
    this.assessmentResults.general.businessProcessOwner = owner;
  }

  @Mutation
  public setEnterpriseProcessOwner(owner: BaseUser[]): void {
    (this.assessmentResults.general.enterpriseProcessOwner as BaseUser[]) = owner;
  }

  @Mutation
  public setGlobalProcessOwner(owner: BaseUser[]): void {
    this.assessmentResults.general.globalProcessOwner = owner;
  }

  @Mutation
  public setProcessLocation(location: string[]): void {
    this.assessmentResults.general.processLocation = location;
  }

  @Mutation
  public setProcessCategory(category: string): void {
    this.assessmentResults.general.processCategory = category;
  }

  @Mutation
  public setTraceId(id: string): void {
    this.assessmentResults.general.traceId = id;
  }

  @Mutation
  public setAutomationTableRows(rows: RadioTableRow[]): void {
    this.assessmentResults.automation.automationTableRows = rows;
  }

  @Mutation
  public setReadinessLevels(levels: ReadinessLevel[]): void {
    this.assessmentResults.automation.readinessLevels = levels;
  }

  @Mutation
  public setProcessRegulation(processRegulation: string): void {
    this.assessmentResults.business.processRegulation = processRegulation;
  }

  @Mutation
  public setRegulations(regs: string[]): void {
    this.assessmentResults.business.regulations = regs;
  }

  @Mutation
  public setGoverningProcess(gp: string): void {
    this.assessmentResults.business.governingProcess = gp;
  }

  @Mutation
  public setProcessTypeRadio(pt: string): void {
    this.assessmentResults.business.processTypeRadio = pt;
  }

  @Mutation
  public setProcessTypeChecks(checks: string[]): void {
    this.assessmentResults.business.processTypeChecks = checks;
  }

  @Mutation
  public setPersonalInfoRadio(info: string): void {
    this.assessmentResults.business.personalInfoRadio = info;
  }

  @Mutation
  public setPersonalInfoChecks(info: string[]): void {
    this.assessmentResults.business.personalInfoChecks = info;
  }

  @Mutation
  public setSystems(sys: string): void {
    this.assessmentResults.business.systems = sys;
  }

  @Mutation
  public setSystemIntegrations(integrations: string[]): void {
    this.assessmentResults.business.systemIntegrations = integrations;
  }

  @Mutation
  public setRegulationList(reg: string): void {
    this.assessmentResults.business.regulationList = reg;
  }

  @Mutation
  public setGoverningProcessList(gov: string): void {
    this.assessmentResults.business.governingProcessList = gov;
  }

  @Mutation
  public setAutomationPercent(percent: number): void {
    this.assessmentResults.value.automationPercent = percent;
  }

  @Mutation
  public setAutomationRepeat(repeat: string): void {
    this.assessmentResults.value.automationRepeats = repeat;
  }

  @Mutation
  public setNumberOfRepeats(num: number): void {
    this.assessmentResults.value.numberOfRepeats = num;
  }

  @Mutation
  public setNumberOfEmployees(num: number): void {
    this.assessmentResults.value.numberOfEmployees = num;
  }

  @Mutation
  public setNumberOfHours(hours: number): void {
    this.assessmentResults.value.numberOfHours = hours;
  }

  @Mutation
  public setNumberOfDays(days: number): void {
    this.assessmentResults.value.numberOfDays = days;
  }

  @Mutation
  public setValueTableRows(rows: RadioTableRow[]): void {
    this.assessmentResults.value.valueTableRows = rows;
  }

  @Mutation
  public resetAssessment(): void {
    this.assessmentResults = initialAssessmentState();
    this.tabList = initialTabsState();
    this.activeTab = initialActiveTab();
  }
}

export const AssessmentModule = getModule(Assessment);
