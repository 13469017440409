
import { Component, Prop, Vue } from 'vue-property-decorator';
import { randId } from '@/utils/components';

@Component
export default class HelpTooltip extends Vue {
  @Prop({ type: String, required: true }) text!: string;
  @Prop({ type: String, default: randId() }) id!: string;
  @Prop({ type: String, default: 'info' }) icon!: string;
  @Prop({ type: String, default: 'above' }) placement!: 'above' | 'below' | 'before' | 'after';
  @Prop({ type: String, default: 'help' }) cursor!: string;
  @Prop({ type: String }) customColor?: string;
  @Prop(Boolean) secondary!: boolean;

  get classes() {
    return {
      secondary: this.secondary,
    };
  }

  get styles() {
    return {
      cursor: this.cursor,
      color: this.customColor,
    };
  }
}
