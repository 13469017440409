import { GskLocation, TechChampion } from '@/Rpa/types/assessment.types';
import { SelectOption } from '@/components/form/form.types';

interface AddBotTypes {
  PROJECT: string;
  BOT: string;
}

export enum RpaEnvIds {
  Dev = 1,
  QA = 2,
  PROD = 3,
}

export enum RpaBuIds {
  GeneralPool = 0,
  GAD = 1,
  PharmaTech = 2,
  GMS = 3,
  Vaccines = 4,
  RDTech = 5,
  CoreTech = 6,
  ConsumerHealth = 7,
  Platforms = 8,
  TrainingPOC = 9,
}

export enum RpaLicenseTypeIds {
  None = 1,
  BotCreator = 2,
  UnattendedBotRunner = 3,
  AttendedBotRunner = 4,
}

export const RpaLicenseTypesById: Record<string, number> = {
  NONE: RpaLicenseTypeIds.None,
  ATTENDEDRUNTIME: RpaLicenseTypeIds.AttendedBotRunner,
  RUNTIME: RpaLicenseTypeIds.UnattendedBotRunner,
  DEVELOPMENT: RpaLicenseTypeIds.BotCreator,
};

export const RpaEnvById: Record<string, number> = {
  dev: RpaEnvIds.Dev,
  qa: RpaEnvIds.QA,
  prod: RpaEnvIds.PROD,
};

export const AddBotType: AddBotTypes = {
  PROJECT: 'project',
  BOT: 'bot',
};

export const GenericCiIds = [
  'S236896014', // dev
  'S236843279', // uat
  'S237549528', // prod
];

export const systemsTitle =
  'List the systems and technologies essential to the process and briefly explain what each of them are used for';

export const processRegulationTitle = 'Does your process need to comply with any regulations?';

export const governingProcessTitle =
  'Are there any SOPs, global and / or local, governing the process?';

export const processTypeTitle = 'Identify the type(s) of data the automation will process';

export const personalInfoTitle =
  'Identify the type(s) of personal information the automation will process';

export const locationOptions: GskLocation[] = [
  {
    value: 'Gsk House',
    detail: 'London, England, United Kingdom',
  },
  {
    value: 'Stevenage',
    detail: 'Stevenage, England, United Kingdom',
  },
  {
    value: 'Wavre',
    detail: 'Wavre, Belgium',
  },
  {
    value: 'Poznan',
    detail: 'Poznan, Poland',
  },
  {
    value: 'Upper Providence',
    detail: 'Collegeville, Pennsylvania, United States',
  },
  {
    value: 'Warren',
    detail: 'Warren, New Jersey, United States',
  },
  {
    value: 'RTP',
    detail: 'Research Triangle Park, North Carolina, United States',
  },
  {
    value: 'Bentonville',
    detail: 'Bentonville, Arkansas, United States',
  },
  {
    value: 'Navy Yard',
    detail: 'Philadelphia, Pennsylvania, United States',
  },
  {
    value: 'Gsk Asia House',
    detail: 'Rochester Park, Singapore',
  },
  {
    value: 'Japan',
    detail: 'Tokyo, Japan',
  },
  {
    value: 'Stockley Park',
    detail: 'West Drayton, England, United Kingdom',
  },
];

export const techChampionsByBusinessUnit: Record<string, TechChampion[]> = {
  'Consumer Health': [
    {
      name: 'Sudeep Gupta',
      email: 'sudeep.x.gupta@gsk.com',
      mudId: 'sg780320',
    },
  ],
  'Tech - GP&T': [
    {
      name: 'Darren Parker',
      email: 'darren.x.parker@gsk.com',
      mudId: 'dp36375',
    },
    {
      name: 'Scott Collins',
      email: 'gregory.s.collins@gsk.com',
      mudId: 'gsc58347',
    },
    {
      name: 'Niket Ghai',
      email: 'niket.x.ghai@gsk.com',
      mudId: 'nxg37302',
    },
    {
      name: 'Prashant Atre',
      email: 'prashant.x.atre@gsk.com',
      mudId: 'pxa17111',
    },
  ],
  'Global functions': [
    {
      name: 'Sushma Swamy',
      email: 'sushma.x.swamy@gsk.com',
      mudId: 'ss251580',
    },
    {
      name: 'Jumbo Mahavuthivanij',
      email: 'jumbo.x.mahavuthivanij@gsk.com',
      mudId: 'mm761446',
    },
    {
      name: 'Michael Kirby',
      email: 'michael.y.kirby@gsk.com',
      mudId: 'myk74471',
    },
    {
      name: 'Chandra Shekhar',
      email: 'chandra.8.shekhar@gsk.com',
      mudId: 'cs642649',
    },
    {
      name: 'Shalini Singh',
      email: 'shalini.8.singh@gsk.com',
      mudId: 'sxs29981',
    },
  ],
  'PSC Tech/GMS': [
    {
      name: 'Dominic Thornton-Flowers',
      email: 'dominic.g.thornton-flowers@gsk.com',
      mudId: 'dgt91042',
    },
  ],
  'Tech - Pharma': [
    {
      name: 'Agnelo Sequeira',
      email: 'agnelo.f.sequeira@gsk.com',
      mudId: 'sequea00',
    },
    {
      name: 'Suresh Babu Kanamurlapudi',
      email: 'suresh.x.kanamurlapudi@gsk.com',
      mudId: 'sk266228',
    },
  ],
  Platforms: [
    {
      name: 'Scott Collins',
      email: 'gregory.s.collins@gsk.com',
      mudId: 'gsc58347',
    },
  ],
  'Tech - R&D': [
    {
      name: 'Diane Hutchinson',
      email: 'diane.2.hutchinson@gsk.com',
      mudId: 'dh70737',
    },
    {
      name: 'Asad Zia',
      email: 'asad.x.zia@gsk.com',
      mudId: 'az947865',
    },
    {
      name: 'Prince Kumar Singh',
      email: 'prince.x.kumarsingh@gsk.com',
      mudId: 'pk680554',
    },
  ],
  Vaccines: [
    {
      name: 'Vinciane Polet',
      email: 'vinciane.x.polet@gsk.com',
      mudId: 'vp295713',
    },
    {
      name: 'Ankit Pandey',
      email: 'ankit.8.pandey@gsk.com',
      mudId: 'ap578447',
    },
    {
      name: 'Prageet Dak',
      email: 'prageet.x.dak@gsk.com',
      mudId: 'pd188006',
    },
  ],
};

export const businessUnits: string[] = [];

export const integrationOptions: SelectOption[] = [
  {
    value: 'SAP',
  },
  {
    value: 'Shared Drive',
  },
  {
    value: 'Integrify',
  },
  {
    value: 'Microsoft Project Online',
  },
  {
    value: 'AIC',
  },
  {
    value: 'e-Track',
  },
  {
    value: 'Concur',
  },
  {
    value: 'Sharepoint',
  },
  {
    value: 'MS Office',
  },
  {
    value: 'Email',
  },
  {
    value: 'IQ Bot',
  },
  {
    value: 'Google Cloud Platforms',
  },
  {
    value: 'Microsoft Azure',
  },
  {
    value: 'Amazon Web Services',
  },
  {
    value: 'Cadency',
  },
  {
    value: 'Harmony PLM',
  },
  {
    value: 'Harmony AMS',
  },
  {
    value: 'OCR',
  },
  {
    value: 'Workday',
  },
  {
    value: 'ADP',
  },
  {
    value: 'My Learning',
  },
  {
    value: 'SQL DB',
  },
];

export enum BotSystemMnemonic {
  V11 = 'V11',
  A360 = 'A360',
};
