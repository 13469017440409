
  import { Platforms } from '@/constants';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  
  @Component
  export default class PlatformTypeChip extends Vue {
    @Prop(String) text!: string;
    @Prop(Boolean) displayName!: boolean;
    
    get isAzureType() {
      return this.platformType === Platforms['AZURE'].type;
    }

    get platformType() {
      if (this.displayName) {
        return this.text
      } else {
        return this.text === Platforms['AZURE'].readableName ? 'Azure' : 'GCP';
      }
    }

    get isPlatformEnabled() {
      return FeatureFlagsModule.platformEnabled || false;
    }
  }
  