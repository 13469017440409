
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import { RawLocation } from 'vue-router';
import once from 'lodash/once';
import { RouteNames } from '@/constants';
import VerticalTab from '@/components/Publish/VerticalTab.vue';
import { UITab } from '@/types';
import GeneralDetails from '@/Rpa/RpaAssessmentTool/GeneralDetails.vue';
import ValueDetails from '@/Rpa/RpaAssessmentTool/ValueDetails.vue';
import AutomationDetails from '@/Rpa/RpaAssessmentTool/AutomationDetails.vue';
import BusinessDetails from '@/Rpa/RpaAssessmentTool/BusinessDetails.vue';
import { AssessmentModule } from '@/Rpa/store/modules/assessment.module';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { techChampionsByBusinessUnit } from '@/Rpa/constants';
import { TechChampion } from '@/Rpa/types/assessment.types';
import GAnalytics from '@/components/GAnalytics';
import { ClickData } from '@/analytics';
import UserCircle from '@/components/UserCircle.vue';

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

const jiraJquery = () => {
  const { hostname } = location;
  if (/^developer\./.test(hostname)) {
    // prod instance of Jira Jquery
    return 'https://myjira.gsk.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN/47mr8g/805004/ce925cd250338ce2d5051c7b85056f9c/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=77810897';
  } else {
    // dev instance of Jira Jquery
    return 'https://myjira.gsk.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN/47mr8g/805004/ce925cd250338ce2d5051c7b85056f9c/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=c11886d0';
  }
};

const setUpJiraJquery = once(() => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = jiraJquery();
  document.head.appendChild(script);
});

@Component({
  components: {
    FullScreenForm,
    VerticalTab,
    GeneralDetails,
    ValueDetails,
    AutomationDetails,
    BusinessDetails,
    GAnalytics,
    UserCircle,
  },
})
export default class OpportunityAssessment extends Vue {
  private modalClosed = false;
  private closeRoute: RawLocation = {
    name: RouteNames.ListingType,
    params: {
      type: 'rpa',
    },
  };

  private hasSubmitted = false;

  closeAssessment(clickAnalytics: () => void) {
    clickAnalytics();
    AssessmentModule.resetAssessment();
    this.$router.replace(this.closeRoute);
  }

  finishAssessment() {
    AssessmentModule.resetAssessment();
  }

  user(champ: TechChampion) {
    return {
      mudId: champ.mudId,
      email: champ.email,
      fullName: champ.name,
    };
  }

  get closeAnalytics(): ClickData {
    return {
      clickTarget: 'Abandoned Page',
    };
  }

  @Watch('activeTab.key')
  scrollTop() {
    const elem = document.querySelector('#fs-layout-main');
    if (elem) {
      elem.scrollTop = 0;
    }
  }

  onSubmit() {
    this.hasSubmitted = true;
  }

  public get traceId(): string {
    if (this.hasSubmitted) {
      return AssessmentModule.assessmentResults.general.traceId as string;
    } else {
      return 'Failed to generate Trace ID';
    }
  }

  public get techChampion(): TechChampion[] {
    if (this.hasSubmitted) {
      const { processCategory } = AssessmentModule.assessmentResults.general;
      return techChampionsByBusinessUnit[processCategory as string];
    } else {
      return [];
    }
  }

  public get tabList(): UITab[] {
    return AssessmentModule.tabList;
  }

  public get activeTab(): UITab {
    return AssessmentModule.activeTab;
  }

  public set activeTab(tab: UITab) {
    AssessmentModule.updateActiveTab(tab);
  }
}
