
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import { RadioTableRow } from '@/Rpa/types/assessment.types';
import HelpTooltip from '@/components/HelpTooltip.vue';

@Component({
  components: {
    HelpTooltip,
  },
})
export default class RadioTable extends Vue {
  @Prop(Array) readonly columns!: string[];
  @Prop(Array) readonly value!: RadioTableRow[];

  @Emit('input')
  onChange(title: string, e: Event) {
    let value = cloneDeep(this.value);

    value.forEach(radio => {
      if (radio.title === title) {
        radio.radios.forEach(r => {
          if (r.value === (e.target as HTMLInputElement).value) {
            r.checked = true;
          } else {
            r.checked = false;
          }
        });
      }
    });
    return value;
  }
}
