
import { Component, Vue } from 'vue-property-decorator';
import { UITab } from '@/types';
import { RequiredProp } from '@/utils/components';

@Component
export default class VerticalTab extends Vue {
  @RequiredProp(Array) readonly tabs!: UITab[];
  @RequiredProp(Object) readonly activeTab!: UITab;

  update(tab: UITab) {
    this.$emit('update:activeTab', tab);
  }

  classes(tab: UITab) {
    return {
      active: tab.key === this.activeTab.key,
      inactive: tab.key !== this.activeTab.key && tab.disabled,
      normal: !tab.disabled && tab.key !== this.activeTab.key,
      isDisabled: tab.disabled,
    };
  }
}
